import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {

}

class Banner extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.banner = args.banner || '';
        this.content = args.content || '';
        this.href = args.href || '';
        this.sort = args.sort || 0;
    }

    static get data() {
        return _data;
    }

    static fetch() {
        let url = `${window.BASE_URL}/api/banner`
        return m.request({
            method: 'GET',
            url: url,
        }).then((response) => {
            return response
        })
    }

    static load(id) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/banner/${id}`,
        })
    }

    static save(data) {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/banner`,
            data: data
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/banner/${id}`,
        })
    }
}

export default Banner