import m from 'mithril'
import stream from 'mithril/stream'
import classNames from 'classnames'
import * as Menu from 'app/models/menu'
import {
    User
} from 'app/models'

const clickEvent = (dom, toggle, e) => {
    if (!dom.contains(e.target)) {
        toggle(null)
        m.redraw()
    }
}


class MenuComponent {
    constructor(vnode) {
        this.menuToggle = vnode.attrs.navbarToggle
        this.roles = []
        User.load(window.USER_ID).then((response) => {
            this.roles = JSON.parse(response.permission)
        })
        // this.auth = vnode.attrs.auth
        this.toggle = stream()
    }
    oncreate(vnode) {
        document.addEventListener('click', clickEvent.bind(null, vnode.dom, this.toggle), false)
    }
    view({
        attrs
    }) {
        return [
            m("div#sidebar-menu",
                [
                    m("ul",
                        [
                            Menu.ManagementItems.map((item, index) => {
                                // if (this.roles.includes(item.auth)) {
                                    return m("li.submenu", {
                                        class: classNames({
                                            'dropdown': (item.children && item.children.length > 0)
                                        }),
                                        onmouseover: (e) => {
                                            e.preventDefault()
                                            if (item.children && item.children.length > 0) {
                                                this.toggle(index)
                                            }

                                        },
                                        onmouseout: (e) => {
                                            e.preventDefault()
                                            if (item.children && item.children.length > 0) {
                                                this.toggle(null)
                                            }
                                        }
                                    }, [
                                            (item.children && item.children.length > 0) ? [
                                                m("a.has-arrow.bg-transparent[href='#'][aria-expanded='false']", {
                                                    style: {
                                                        borderColor: 'transparent'
                                                    },
                                                    // onclick: (e) => {
                                                    // e.preventDefault()
                                                    // this.toggle(null)
                                                    // attrs.navbarToggle(false)

                                                    // m.route.set(item.href)
                                                    // }
                                                }, [
                                                        m(`i.${item.icon}`),
                                                        m("span.pl-2.align-middle",
                                                            item.title
                                                        )
                                                    ]),
                                                m("ul[aria-expanded='false']",
                                                    item.children.map((child) => {
                                                        return [m('a[href="#"].nav-link', {
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                this.toggle(null)
                                                                attrs.navbarToggle(false)
                                                                m.route.set(child.href)
                                                            }
                                                        }, [
                                                                m(`i.${item.icon}`),
                                                                m("span.pl-2.align-middle",
                                                                    child.title
                                                                )
                                                            ])]
                                                    })
                                                )
                                            ] : [
                                                    m('a[href="#"].nav-link', {
                                                        onclick: (e) => {
                                                            e.preventDefault()
                                                            this.toggle(null)
                                                            if (window.isMobile) {
                                                                window.menuclick()
                                                            }
                                                            m.route.set(item.href)
                                                        }
                                                    }, [
                                                            m(`i.${item.icon}`),
                                                            m("span.pl-2.align-middle",
                                                                item.title
                                                            )
                                                        ])
                                                ]
                                        ])
                                // }
                            }),
                        ]
                    )
                ]
            )
        ]
    }
}

class MenuItemDropDown {
    constructor(vnode) {
        // this.auth = vnode.attrs.auth
        this.toggle = stream()
    }
    view({
        attrs
    }) {
        return
    }
}

class MainMenu {
    view({
        attrs
    }) {
        return [
            m(MenuComponent, {
                navbarToggle: attrs.navbarToggle,
                auth: attrs.auth,
                // isSite: attrs.isSite
            }),
        ]
    }
}

export default MainMenu