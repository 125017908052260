export * from './menu'
export {
    default as News
}
from './news'
export {
    default as Cookie
}
    from './cookie'
export {
    default as SignUp
}
    from './signUp'
export {
    default as Customer
}
    from './customer'
export {
    default as ContactUs
}
    from './contactUs'
export {
    default as Mail
}
    from './mail'
export {
    default as Banner
}
    from './banner'






export {
    default as User
}
from './user'

export {
    default as Authority
}
from './authority'

