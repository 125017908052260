export { default as DashboardPage } from './management/dashboard'
export { default as NewsPage } from './management/news'
export { default as CookiePage } from './management/cookie'
export { default as SignUpPage } from './management/signUp'
export { default as CustomerPage } from './management/customer'
export { default as ContactUsPage } from './management/contactUs'



export { default as AccountPage } from './management/account'
export { default as ErrorPage } from './error'

