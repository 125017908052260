import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {

}

class Mail extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.email = args.email || '';
        this.type = args.type || '';
    }

    static get data() {
        return _data;
    }

    static fetch() {
        let url = `${window.BASE_URL}/api/mail`
        return m.request({
            method: 'GET',
            url: url,
        }).then((response) => {
            return response
        })
    }

    static load(type) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/mail/${type}`,
        })
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/mail`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/mail/${id}`,
        })
    }
}

export default Mail