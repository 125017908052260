import m from 'mithril'
import {
    Mail,
    Banner
} from "app/models"
import stream from 'mithril/stream'
import * as Procedure from "app/models/procedure-status"
import {
    Dialog
} from 'components'
import moment from 'moment'
import EditComponent from './dialogs/event'
import {
    BannersPhotosUploader,
} from 'components/uploader'

class DashboardPage {
    constructor() {        
        this.mailsContact = []
        this.mailsSignup = []
        this.mailsCustomer = []

        Mail.load("contact").then((response) => {
            this.mailsContact = response
        })
        Mail.load("signup").then((response) => {
            this.mailsSignup = response
        })
        Mail.load("customer").then((response) => {
            this.mailsCustomer = response
        })
        this.banners = stream([])
        Banner.fetch().then((response) => {
            this.banners(response)
        })
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder", [
                        m("h1.main-title.float-left.text-menu", m("i.lnr.lnr-home.font-weight-bold.mr-3"), "首頁"),
                        m("ol.breadcrumb.float-right",
                            [
                                m("li.breadcrumb-item.active", "首頁")
                            ]
                        ), m(".clearfix")
                    ])
                )
            ),
            m(".row",
                m(".col-md-12",[
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-table.mr-2"), "首頁視覺圖列表"]),
                                            m("button.btn.btn-primary.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Banner.save(this.banners()).then((response)=>{
                                                        swal({
                                                            title: "儲存完畢!",
                                                            text: "已成功編輯資訊，請至列表查看!!",
                                                            icon: "success",
                                                            buttons: false,
                                                            timer: 1800,
                                                        })
                                                    })
                                                }
                                            }, [m("i.fa.fa-plus-circle.mr-1"), "儲存首頁視覺圖"])
                                        ]
                                    ),
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        m(BannersPhotosUploader, {
                                            items: this.banners,
                                            temppath: `${window.BASE_URL}/temp/`,
                                            storepath: `${window.BASE_URL}/images/banners/`,
                                            url: `${window.BASE_URL}/api/upload/banners?width=1920&height=1000&folder=banners`,
                                            width: 1920,
                                            height: 1000,
                                            folder: "banners"
                                        })
                                    ])
                                ])
                            ]
                        )
                    )]
                ),
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-table.mr-2"), "發送信箱設定列表"]),
                                            m("button.btn.btn-primary.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(EditComponent, {
                                                        attrs: {
                                                            model: new Mail(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            if (returnValue) {
                                                                Mail.load("contact").then((response) => {
                                                                    this.mailsContact = response
                                                                })
                                                                Mail.load("signup").then((response) => {
                                                                    this.mailsSignup = response
                                                                })
                                                                Mail.load("customer").then((response) => {
                                                                    this.mailsCustomer = response
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-plus-circle.mr-1"), "新增信箱"])
                                        ]
                                    ),
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1",[
                                        m(".col-12",[
                                            m("span.text-dark","線上報名")
                                        ])
                                    ]),
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        this.mailsSignup.map((_mail) => {
                                            return [
                                                m(".col-3.text-center", [
                                                    m(".p-1.py-4.m-1.border.bg-light", [
                                                        m("span.text-dark.px-5", _mail.email),
                                                        m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                            style: {
                                                                backgroundColor: "#ffbdbe"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                swal({
                                                                    title: "是否確認刪除",
                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            swal("您的資料已成功刪除", {
                                                                                icon: "success",
                                                                                buttons: {
                                                                                    cancel: false,
                                                                                    confirm: "確認",
                                                                                },
                                                                            });
                                                                            Mail.delete(_mail.id).then(() => {
                                                                                Mail.load("signup").then((response) => {
                                                                                    this.mailssignup = response
                                                                                })
                                                                            })
                                                                        } else {
                                                                            swal("您的資料未被刪除");
                                                                        }
                                                                    });
                                                            }
                                                        },
                                                            [
                                                                m("i.fa.fa-trash"),
                                                            ]
                                                        )
                                                    ])
                                                ])
                                            ]
                                        })

                                    ]),

                                    m(".row.w-100.text-light.border-bottom.pb-1.pt-4", [
                                        m(".col-12", [
                                            m("span.text-dark", "客製合作")
                                        ])
                                    ]),
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        this.mailsCustomer.map((_mail) => {
                                            return [
                                                m(".col-3.text-center", [
                                                    m(".p-1.py-4.m-1.border.bg-light", [
                                                        m("span.text-dark.px-5", _mail.email),
                                                        m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                            style: {
                                                                backgroundColor: "#ffbdbe"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                swal({
                                                                    title: "是否確認刪除",
                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            swal("您的資料已成功刪除", {
                                                                                icon: "success",
                                                                                buttons: {
                                                                                    cancel: false,
                                                                                    confirm: "確認",
                                                                                },
                                                                            });
                                                                            Mail.delete(_mail.id).then(() => {
                                                                                Mail.load("customer").then((response) => {
                                                                                    this.mailsCustomer = response
                                                                                })
                                                                            })
                                                                        } else {
                                                                            swal("您的資料未被刪除");
                                                                        }
                                                                    });
                                                            }
                                                        },
                                                            [
                                                                m("i.fa.fa-trash"),
                                                            ]
                                                        )
                                                    ])
                                                ])
                                            ]
                                        })

                                    ]),

                                    m(".row.w-100.text-light.border-bottom.pb-1.pt-4", [
                                        m(".col-12", [
                                            m("span.text-dark", "聯絡我們")
                                        ])
                                    ]),
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        this.mailsContact.map((_mail) => {
                                            return [
                                                m(".col-3.text-center", [
                                                    m(".p-1.py-4.m-1.border.bg-light", [
                                                        m("span.text-dark.px-5", _mail.email),
                                                        m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                            style: {
                                                                backgroundColor: "#ffbdbe"
                                                            },
                                                            onclick: (e) => {
                                                                e.preventDefault()
                                                                swal({
                                                                    title: "是否確認刪除",
                                                                    text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    icon: "warning",
                                                                    dangerMode: true,
                                                                    buttons: ["取消", "確認"]
                                                                })
                                                                    .then((willDelete) => {
                                                                        if (willDelete) {
                                                                            swal("您的資料已成功刪除", {
                                                                                icon: "success",
                                                                                buttons: {
                                                                                    cancel: false,
                                                                                    confirm: "確認",
                                                                                },
                                                                            });
                                                                            Mail.delete(_mail.id).then(() => {
                                                                                Mail.load("contact").then((response) => {
                                                                                    this.mailsContact = response
                                                                                })
                                                                            })
                                                                        } else {
                                                                            swal("您的資料未被刪除");
                                                                        }
                                                                    });
                                                            }
                                                        },
                                                            [
                                                                m("i.fa.fa-trash"),
                                                            ]
                                                        )
                                                    ])
                                                ])
                                            ]
                                        })

                                    ])
                                ])
                            ]
                        )
                    )
                )
            )
        ]
    }
}

export default DashboardPage