import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {

}

class Cookie extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.title = args.title || '';
        this.teacher = args.teacher || '';
        this.type = args.type || '';
        this.photos = args.photos || '';
        this.content = args.content || '';
        this.price = args.price || '';
        this.memo = args.memo || '';        
        this.sort = args.sort || 1;
        this.isVisit = args.isVisit || true;
        this.dates = args.dates || [];
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get data() {
        return _data;
    }

    static fetchall() {
        Cookie.loading = true
        let url = `${window.BASE_URL}/api/cookie/getall`
        return m.request({
            method: 'GET',
            url: url,
        }).then((response) => {
            Cookie.loading = false
            return response
        })
    }

    static fetch(page) {
        Cookie.loading = true
        page = (page === undefined) ? Cookie.pageIndex : page
        Cookie.pageIndex = page

        let url = `${window.BASE_URL}/api/cookie/getpage?pageIndex=${page}`
        return m.request({
            method: 'GET',
            url: url,
        }).then((response) => {
            Cookie.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _data = response.data
            Cookie.loading = false
        })
    }

    static load(id) {
        if (id == 0) {
            return m.request({
                method: 'GET',
                url: `${window.BASE_URL}/api/cookie`,
            })
        }
        else {
            return m.request({
                method: 'GET',
                url: `${window.BASE_URL}/api/cookie/${id}`,
            })
        }
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/cookie`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/cookie/${id}`,
        })
    }
}

export default Cookie