import m from 'mithril'
import Uploader from './core'

class PhotoUploader {
    constructor(vnode) {
        this.uploader = new Uploader()
        this.filename = vnode.attrs.filename
        this.isDisabled = vnode.attrs.disabled
    }
    view(vnode) {
        return m('figure', [
            m('.g-preivew-container.mx-auto', {
                style: {
                    width: (!window.isMobile) ? vnode.attrs.viewW + "px" : "100%",
                    height: (!window.isMobile) ? vnode.attrs.viewH + "px" : "100%",
                },
                onclick: (e) => {
                    vnode.dom.querySelector('.g-file_browser').click()
                }
            }, [
                    m('.g-preivew-content.d-inline-block.mx-auto.border.rounded.p-1.position-relative',{
                        style: {
                            width: (!window.isMobile) ? vnode.attrs.viewW + "px" : "100%",
                            height: (!window.isMobile) ? vnode.attrs.viewH + "px" : "100%",
                        },
                    }, [
                        m('img.h-100', {
                            src: this.uploader.imgSrc() ? this.uploader.imgSrc() : vnode.attrs.path
                        }),
                        m('.g-preivew-mask'),
                        m('.d-flex.justify-content-center.align-items-end.position-absolute', {
                            style: {
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                
                            }
                        }, [
                                (this.uploader.progress()) ? [
                                    m('.progress.mb-0', [
                                        m('.determinate', {
                                            style: {
                                                width: `${this.uploader.progress()}%`
                                            }
                                        })
                                    ])
                                ] : ''
                            ])
                    ]),
                    m('input.g-file_browser[type="file"][name="files[]"][accept="image/*"]', {
                        onchange: (e) => {
                            // if (vnode.attrs.isDisabled && !this.isDisabled()) {
                            //     return false
                            // }
                            if (e.target.files.length === 0) {
                                return false
                            }
                            if (!this.uploader.filterImageExtension(e.target.files)) {
                                alert('不支援上傳的檔案格式, 請選擇圖片檔!')
                                return false
                            }
                            this.uploader.uploadImage(e.target.files[0], {
                                url: vnode.attrs.url,
                                width: vnode.attrs.width,
                                height: vnode.attrs.height,
                                folder: vnode.attrs.folder,
                                type: "photo",
                                preview: vnode.attrs.preview
                            }).then((response) => {
                                this.filename(response.filename)
                            })
                        }
                    })
                ]),
            (vnode.attrs.caption) ? [
                m('figcaption.figure-caption.py-1.text-center', [
                    vnode.attrs.caption
                ])
            ] : ''
        ])
    }
}

export default PhotoUploader