import m from 'mithril'
import EditComponent from './edit'
import classNames from 'classnames'
import isMobile from 'lib/detect-mobile'
import moment from 'moment'
import {
    Dialog
} from 'components'
import {
    SignUp,
    Cookie,
    Authority
} from 'app/models'
import {
    Paging,
} from 'components'


class SignUpPage {
    constructor() {
        this.cookies = []
        this.choosen = "all"
        this.datechoosen = null
        Cookie.fetchall().then((response) => {
            this.cookies = response
        })
        this.dates = []
        this.signUps = []
        this.people = []
        SignUp.load(this.choosen).then((response) => {
            this.people = response
        })
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder",
                        [
                            m("h1.main-title.float-left.text-menu",
                                m("i.lnr.lnr-book.font-weight-bold.mr-3"), "線上報名列表"
                            ),
                            m("ol.breadcrumb.float-right",
                                [
                                    m("li.breadcrumb-item",
                                        "首頁"
                                    ),
                                    m("li.breadcrumb-item.active",
                                        "線上報名"
                                    )
                                ]
                            ),
                            m(".clearfix")
                        ]
                    )
                )
            ),
            m(".row",
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                // m(".card-header",
                                //     m('.d-flex.justify-content-between.align-items-center',
                                //         [
                                //             m("h3", [m("i.fa.fa-table.mr-2"), "線上報名列表"]),
                                //             m("button.btn.btn-primary.btn-sm[type='button']", {
                                //                 onclick: (e) => {
                                //                     e.preventDefault()
                                //                     Dialog.show(EditComponent, {
                                //                         attrs: {
                                //                             model: new SignUp({
                                //                                 cookie_id: this.choosen,
                                //                                 cookie_date_id: this.datechoosen,
                                //                             }),
                                //                         },
                                //                         didHide: (returnValue) => {
                                //                             if (returnValue) {
                                //                                 SignUp.load(this.choosen).then((response) => {
                                //                                     this.people = response
                                //                                 })
                                //                             }
                                //                         }
                                //                     })
                                //                 }
                                //             }, [m("i.fa.fa-plus-circle.mr-1"), "線上報名"])
                                //         ]
                                //     ),
                                // ),
                                m(".card-header",
                                    m(".row.w-100.no-gutters", [
                                        m(".col-2.px-3", [
                                            m("select.form-control", {
                                                onchange: (e) => {
                                                    this.choosen = e.target.value
                                                    if (this.choosen == "all"){
                                                        this.dates = []
                                                        SignUp.load(this.choosen).then(( response) => {
                                                            this.people = response
                                                        })
                                                    }
                                                    else{
                                                        this.people = []
                                                        this.dates = this.cookies.filter(p => parseInt(p.id) == parseInt(this.choosen))[0].dates
                                                        SignUp.getall(this.choosen).then((response) => {
                                                            this.people = response
                                                        })
                                                    }
                                                }
                                            }, [
                                                    m('option', {
                                                        value: "all",
                                                        selected: true
                                                    }, "全部"),
                                                    this.cookies.map((_cookie) => {
                                                        return [
                                                            m('option', {
                                                                value: _cookie.id,
                                                                selected: this.choosen == _cookie.id
                                                            }, _cookie.title)
                                                        ]
                                                    })
                                                ]
                                            )
                                        ]),
                                        (this.choosen != "all") ? [
                                            m(".col-2.px-3", [
                                                m("select.form-control", {
                                                    onchange: (e) => {
                                                        this.datechoosen = e.target.value
                                                        if (this.datechoosen == "all"){
                                                            SignUp.getall(this.choosen).then((response) => {
                                                                this.people = response
                                                            })
                                                        }
                                                        else{
                                                            SignUp.load(this.datechoosen).then((response) => {
                                                                this.people = response
                                                            })
                                                        }
                                                    }
                                                }, [
                                                        m('option', {
                                                            value: "all",
                                                            selected: this.datechoosen == "all"
                                                        }, "全部"),
                                                        this.dates.map((_date) => {
                                                            return [
                                                                m('option', {
                                                                    value: _date.id,
                                                                    selected: this.datechoosen == _date.id
                                                                }, _date.name + " - " + _date.time)
                                                            ]
                                                        })
                                                    ]
                                                )
                                            ])
                                        ] : ""

                                    ])
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "序號")
                                        ),
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "姓名")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "日期 / 時間")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "信箱")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "電話")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "狀態")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "備註")
                                        ),

                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "功能")
                                        ),

                                    ]),

                                    (SignUp.loading) ?
                                        [
                                            m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                style: {
                                                    fontSize: "85%",
                                                    fontWeight: 700,
                                                    lineHeight: 1,
                                                }
                                            }, [
                                                    m('.col-12.text-center.py-2', [
                                                        m('span', {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, "資料讀取中...")
                                                    ])
                                                ])

                                        ] : [
                                            (this.people.length == 0) ?
                                                [
                                                    m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                        style: {
                                                            fontSize: "85%",
                                                            fontWeight: 700,
                                                            lineHeight: 1,
                                                        }
                                                    }, [
                                                            m('.col-12.text-center.py-2', [
                                                                m('span', {
                                                                    style: {
                                                                        fontSize: "1.5rem"
                                                                    }
                                                                }, "無資料")
                                                            ])
                                                        ])

                                                ] : [

                                                    this.people.map((_people, index) => {
                                                        return m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                            style: {
                                                                fontSize: "85%",
                                                                fontWeight: 700,
                                                                lineHeight: 1,
                                                                backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                                            }
                                                        }, [
                                                                m(".col-1.text-center",
                                                                    m("span", index + 1)
                                                                ),
                                                                m(".col-1",
                                                                    m("span", _people.name)
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", (_people.cookie_date != null) ? _people.cookie_date.name : "無" ),
                                                                    m("span", '/'),
                                                                    m("span", (_people.cookie_date != null) ? _people.cookie_date.time : "無" )
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _people.email)
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _people.phone)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("span", _people.status)
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _people.memo)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                        style: {
                                                                            backgroundColor: "#e2f0d9"
                                                                        },
                                                                        onclick: (e) => {
                                                                            e.preventDefault()
                                                                            Dialog.show(EditComponent, {
                                                                                attrs: {
                                                                                    model: new SignUp(_people),
                                                                                },
                                                                                didHide: (returnValue) => {
                                                                                    if (returnValue) {
                                                                                        SignUp.load(this.datechoosen).then((response) => {
                                                                                            this.people = response
                                                                                        })
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    },
                                                                        [
                                                                            m("i.fa.fa-edit"),
                                                                        ]
                                                                    ),
                                                                    // m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                    //     style: {
                                                                    //         backgroundColor: "#ffbdbe"
                                                                    //     },
                                                                    //     onclick: (e) => {
                                                                    //         e.preventDefault()
                                                                    //         swal({
                                                                    //             title: "是否確認刪除",
                                                                    //             text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    //             icon: "warning",
                                                                    //             dangerMode: true,
                                                                    //             buttons: ["取消", "確認"]
                                                                    //         })
                                                                    //             .then((willDelete) => {
                                                                    //                 if (willDelete) {
                                                                    //                     swal("您的資料已成功刪除", {
                                                                    //                         icon: "success",
                                                                    //                         buttons: {
                                                                    //                             cancel: false,
                                                                    //                             confirm: "確認",
                                                                    //                         },
                                                                    //                     });
                                                                    //                     SignUp.delete(_people.id).then(() => {
                                                                    //                         SignUp.load(this.datechoosen).then((response) => {
                                                                    //                             this.people = response
                                                                    //                         })
                                                                    //                     })
                                                                    //                 } else {
                                                                    //                     swal("您的資料未被刪除");
                                                                    //                 }
                                                                    //             });
                                                                    //     }
                                                                    // },
                                                                    //     [
                                                                    //         m("i.fa.fa-trash"),
                                                                    //     ]
                                                                    // )
                                                                ),
                                                            ])

                                                    }),
                                                ]
                                        ]
                                ]
                                ),
                                m('.row.no-gutters.px-5', [
                                    (this.people.length > 0) ? [
                                        m('.col-12.py-3.text-right', [
                                            m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                style: {
                                                    backgroundColor: "#e2f0d9"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    window.location.href = `${window.BASE_URL}/api/signUpExport/${this.choosen}/${this.datechoosen}`
                                                }
                                            },
                                                [
                                                    m("span", "匯出Excel"),
                                                ]
                                            ),
                                        ])
                                    ] : ""
                                ]),
                            ]
                        )
                    )
                )
            ),
        ]
    }
}

export default SignUpPage