import m from 'mithril'
import EditComponent from './edit'
import classNames from 'classnames'
import isMobile from 'lib/detect-mobile'
import moment from 'moment'
import DatePicker from 'components/datepick/mithril-datepicker.js'
import {
    Dialog,
    TextBox
} from 'components'
import {
    Customer,
    Cookie,
    Authority
} from 'app/models'
import {
    Paging,
} from 'components'


class CustomerPage {
    constructor() {
        Customer.fetch()
        this.date_from = moment().format('Y-M-D')
        this.date_to = moment().format('Y-M-D')
        this._check = false
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder",
                        [
                            m("h1.main-title.float-left.text-menu",
                                m("i.lnr.lnr-book.font-weight-bold.mr-3"), "客製合作列表"
                            ),
                            m("ol.breadcrumb.float-right",
                                [
                                    m("li.breadcrumb-item",
                                        "首頁"
                                    ),
                                    m("li.breadcrumb-item.active",
                                        "客製合作"
                                    )
                                ]
                            ),
                            m(".clearfix")
                        ]
                    )
                )
            ),
            m(".row",
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                // m(".card-header",
                                //     m('.d-flex.justify-content-between.align-items-center',
                                //         [
                                //             m("h3", [m("i.fa.fa-table.mr-2"), "客製合作列表"]),
                                //             m("button.btn.btn-primary.btn-sm[type='button']", {
                                //                 onclick: (e) => {
                                //                     e.preventDefault()
                                //                     Dialog.show(EditComponent, {
                                //                         attrs: {
                                //                             model: new Customer({
                                //                                 cookie_id: this.choosen,
                                //                                 cookie_date_id: this.datechoosen,
                                //                             }),
                                //                         },
                                //                         didHide: (returnValue) => {
                                //                             if (returnValue) {
                                //                                 Customer.load(this.choosen).then((response) => {
                                //                                     this.people = response
                                //                                 })
                                //                             }
                                //                         }
                                //                     })
                                //                 }
                                //             }, [m("i.fa.fa-plus-circle.mr-1"), "客製合作"])
                                //         ]
                                //     ),
                                // ),
                                m(".card-header",
                                    m(".row.w-100.no-gutters", [
                                        m(".col-6",[
                                            m("span","")
                                        ]),
                                        m('.col-md-2', [
                                            m(".d-flex.mb-1",
                                                [
                                                    m(".input-group-prepend",
                                                        m(".input-group-text.border-0.py-0", m("i.fa.fa-calendar.mr-1"), m('span.small', '開始時間'))
                                                    ),
                                                    m(TextBox, {
                                                        oninput: (e) => {
                                                            this.date_from = e.target.value
                                                            Customer.fetchtime(this.date_from, this.date_to)
                                                            this._check = true
                                                        },
                                                        class: 'mt-0 form-control border-0 py-0',
                                                        value: this.date_from,
                                                        type: 'Date',
                                                    })
                                                ]
                                            ),
                                        ]),
                                        m(".px-2", [
                                            m("span", "")
                                        ]),
                                        m('.col-md-2', [
                                            m(".d-flex.mb-1",
                                                [
                                                    m(".input-group-prepend",
                                                        m(".input-group-text.border-0.py-0", m("i.fa.fa-calendar.mr-1"), m('span.small', '結束時間'))
                                                    ),
                                                    m(TextBox, {
                                                        oninput: (e) => {
                                                            this.date_to = e.target.value
                                                            Customer.fetchtime(this.date_from, this.date_to)
                                                            this._check = true

                                                        },
                                                        class: 'mt-0 form-control border-0 py-0',
                                                        value: this.date_to,
                                                        type: 'Date',
                                                    })
                                                ]
                                            ),
                                        ]),
                                        m('.col-1.py-3.text-right', [
                                            m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                style: {
                                                    backgroundColor: "#e2f0d9"
                                                },
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    window.location.href = `${window.BASE_URL}/api/customerExport/${this.date_from}/${this.date_to}`
                                                }
                                            },
                                                [
                                                    m("span", "匯出Excel"),
                                                ]
                                            ),
                                        ])

                                    ])
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "序號")
                                        ),
                                        m(".col-1",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "公司")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "聯絡人")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "信箱")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "手機")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "人數")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "預算")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "填表時間")
                                        ),

                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "功能")
                                        ),

                                    ]),

                                    (Customer.loading) ?
                                        [
                                            m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                style: {
                                                    fontSize: "85%",
                                                    fontWeight: 700,
                                                    lineHeight: 1,
                                                }
                                            }, [
                                                    m('.col-12.text-center.py-2', [
                                                        m('span', {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, "資料讀取中...")
                                                    ])
                                                ])

                                        ] : [
                                            (Customer.data.length == 0) ?
                                                [
                                                    m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                        style: {
                                                            fontSize: "85%",
                                                            fontWeight: 700,
                                                            lineHeight: 1,
                                                        }
                                                    }, [
                                                            m('.col-12.text-center.py-2', [
                                                                m('span', {
                                                                    style: {
                                                                        fontSize: "1.5rem"
                                                                    }
                                                                }, "無資料")
                                                            ])
                                                        ])

                                                ] : [

                                                    Customer.data.map((_customer, index) => {
                                                        return m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                            style: {
                                                                fontSize: "85%",
                                                                fontWeight: 700,
                                                                lineHeight: 1,
                                                                backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                                            }
                                                        }, [
                                                                m(".col-1.text-center",
                                                                    m("span", index + 1)
                                                                ),
                                                                m(".col-1",
                                                                    m("span", _customer.company)
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _customer.name)
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _customer.email)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("span", _customer.phone)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("span", _customer.persons)
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _customer.estimates)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("span", moment(_customer.created_at).format('Y-M-D'))
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                        style: {
                                                                            backgroundColor: "#e2f0d9"
                                                                        },
                                                                        onclick: (e) => {
                                                                            e.preventDefault()
                                                                            Dialog.show(EditComponent, {
                                                                                attrs: {
                                                                                    model: new Customer(_customer),
                                                                                },
                                                                                didHide: (returnValue) => {
                                                                                    if (returnValue) {
                                                                                        // Customer.fetch()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    },
                                                                        [
                                                                            m("i.fa.fa-edit"),
                                                                        ]
                                                                    ),
                                                                    // m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                    //     style: {
                                                                    //         backgroundColor: "#ffbdbe"
                                                                    //     },
                                                                    //     onclick: (e) => {
                                                                    //         e.preventDefault()
                                                                    //         swal({
                                                                    //             title: "是否確認刪除",
                                                                    //             text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                    //             icon: "warning",
                                                                    //             dangerMode: true,
                                                                    //             buttons: ["取消", "確認"]
                                                                    //         })
                                                                    //             .then((willDelete) => {
                                                                    //                 if (willDelete) {
                                                                    //                     swal("您的資料已成功刪除", {
                                                                    //                         icon: "success",
                                                                    //                         buttons: {
                                                                    //                             cancel: false,
                                                                    //                             confirm: "確認",
                                                                    //                         },
                                                                    //                     });
                                                                    //                     Customer.delete(_people.id).then(() => {
                                                                    //                         Customer.load(this.datechoosen).then((response) => {
                                                                    //                             this.people = response
                                                                    //                         })
                                                                    //                     })
                                                                    //                 } else {
                                                                    //                     swal("您的資料未被刪除");
                                                                    //                 }
                                                                    //             });
                                                                    //     }
                                                                    // },
                                                                    //     [
                                                                    //         m("i.fa.fa-trash"),
                                                                    //     ]
                                                                    // )
                                                                ),
                                                            ])

                                                    }),
                                                ]
                                        ]
                                ]
                                ),
                                m('.row.no-gutters.px-5', [
                                    m('.col-12.pt-3', [
                                        (!this._check)?[
                                        m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                            m(Paging.Pagination, {
                                                pageNo: Customer.paging.pageNo,
                                                pageCount: Customer.paging.pageCount,
                                                pageUrl: Customer.fetch.bind(this)
                                            }),
                                            m(Paging.PageItemCount, {
                                                pageNo: Customer.paging.pageNo,
                                                pageSize: Customer.paging.pageSize,
                                                total: Customer.paging.totalRecordCount
                                            })
                                        ])
                                    ]:"",
                                    ])
                                ]),
                            ]
                        )
                    )
                )
            ),
        ]
    }
}

export default CustomerPage