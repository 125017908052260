import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {

}

class SignUp extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.name = args.name || '';
        this.email = args.email || '';
        this.phone = args.phone || '';
        this.message = args.message || '';
        this.cookie_id = args.cookie_id || '';
        this.cookie_date_id = args.cookie_date_id || '';
        this.status = args.status || "未處理";
        this.memo = args.memo || "";
        this.objects = args.objects || "";
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get data() {
        return _data;
    }

    static fetch(page) {
        SignUp.loading = true
        page = (page === undefined) ? SignUp.pageIndex : page
        SignUp.pageIndex = page

        let url = `${window.BASE_URL}/api/signUp/getpage?pageIndex=${page}`
        return m.request({
            method: 'GET',
            url: url,
        }).then((response) => {
            SignUp.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _data = response.data
            SignUp.loading = false
        })
    }
    static load(signUp) {
        SignUp.loading = true
        if (signUp == "all") {
            return m.request({
                method: 'GET',
                url: `${window.BASE_URL}/api/signUp`,
            }).then((response) => {
                SignUp.loading = false
                return response
            })
        }
        else {
            return m.request({
                method: 'GET',
                url: `${window.BASE_URL}/api/signUp/${signUp}`,
            }).then((response) => {
                SignUp.loading = false
                return response
            })
        }
    }

    static getall(signUp) {
        SignUp.loading = true
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/signUpGetAll/${signUp}`,
        }).then((response) => {
            SignUp.loading = false
            return response
        })
    }

    static export(signUp, timeAt) {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/signUpExport/${signUp}/${timeAt}`,
        }).then((response) => {
            return response
        })
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/signUp`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/signUp/${id}`,
        })
    }
}

export default SignUp