import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    Editor,
} from 'components'

import DatePicker from 'components/datepick/mithril-datepicker.js'
import {
    MultiPhotosUploader,
} from 'components/uploader'


class EditComponent {
    constructor(vnode) {
        this.model = vnode.attrs.model
        this.status = ['未處理', '處理中', '已處理']
        if (this.model.id != 0) {

        }
    }


    save() {
        this.model.save().then((response) => {
            Dialog.close(true)
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return [m(".modal-dialog.modal-lg[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", (this.model.id == 0) ? "新增" : "編輯"),
                            m("button.close", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("",
                            m("form[id='basic-form'][method='post'][novalidate]",
                                m('.row.m-0', [
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "姓名"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.name = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.name,
                                                    type: 'text',
                                                    readonly: true

                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-8',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "信箱"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.email = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.email,
                                                    type: 'text',
                                                    readonly: true

                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "電話"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.phone = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.phone,
                                                    type: 'text',
                                                    readonly:true
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-8',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "訊息"),
                                            [
                                                m("textarea.form-control[readonly]", {
                                                    row: "3",
                                                    value: this.model.message,
                                                    oninput: (e) => {
                                                        this.model.message = e.target.value
                                                    },
                                                })
                                            ]
                                        ]),
                                    ),
                                ]),

                                m(".row.w-100.py-3.pl-3", [
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "處理狀況"),
                                            [
                                                m("select.form-control", {
                                                    onchange: (e) => {
                                                        this.model.status = e.target.value
                                                    }
                                                }, [
                                                        m("option"),
                                                        this.status.map((item, index) => {
                                                            return m('option', {
                                                                value: item,
                                                                selected: this.model.status == item
                                                            }, item)
                                                        })
                                                    ]
                                                )
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-8',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "備註"),
                                            [
                                                m("textarea.form-control", {
                                                    row: "3",
                                                    value: this.model.memo,
                                                    oninput: (e) => {
                                                        this.model.memo = e.target.value
                                                    },
                                                })
                                            ]
                                        ]),
                                    ),
                                ]),

                            )
                        )
                    ),

                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                [m("i.fa.fa-times-circle"), "關閉"]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                    swal({
                                        title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                                        text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                                        icon: "success",
                                        buttons: false,
                                        timer: 1800,
                                    })
                                }
                            },

                                [m("i.fa.fa-check-circle"), "儲存"])
                        ],
                    )
                ]
            )
        ])]
    }
}

export default EditComponent
