import m from 'mithril'
import EditComponent from './edit'
import classNames from 'classnames'
import isMobile from 'lib/detect-mobile'
import moment from 'moment'
import {
    Dialog
} from 'components'
import {
    Cookie,
    Authority
} from 'app/models'
import {
    Paging,
} from 'components'


class CookiePage {
    constructor() {
        Cookie.fetch()
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder",
                        [
                            m("h1.main-title.float-left.text-menu",
                                m("i.lnr.lnr-book.font-weight-bold.mr-3"), "文青廚房列表"
                            ),
                            m("ol.breadcrumb.float-right",
                                [
                                    m("li.breadcrumb-item",
                                        "首頁"
                                    ),
                                    m("li.breadcrumb-item.active",
                                        "文青廚房"
                                    )
                                ]
                            ),
                            m(".clearfix")
                        ]
                    )
                )
            ),
            m(".row",
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-table.mr-2"), "文青廚房列表"]),
                                            m("button.btn.btn-primary.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(EditComponent, {
                                                        attrs: {
                                                            model: new Cookie(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            if (returnValue) {
                                                                Cookie.fetch()
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-plus-circle.mr-1"), "新增文青廚房"])
                                        ]
                                    ),
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "序號")
                                        ),
                                        m(".col-5",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "標題")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "顯示")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "分類")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "費用")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "排序")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "功能")
                                        ),

                                    ]),

                                    (Cookie.loading) ?
                                        [
                                            m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                style: {
                                                    fontSize: "85%",
                                                    fontWeight: 700,
                                                    lineHeight: 1,
                                                }
                                            }, [
                                                    m('.col-12.text-center.py-2', [
                                                        m('span', {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, "資料讀取中...")
                                                    ])
                                                ])

                                        ] : [
                                            (Cookie.data.length == 0) ?
                                                [
                                                    m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                        style: {
                                                            fontSize: "85%",
                                                            fontWeight: 700,
                                                            lineHeight: 1,
                                                        }
                                                    }, [
                                                            m('.col-12.text-center.py-2', [
                                                                m('span', {
                                                                    style: {
                                                                        fontSize: "1.5rem"
                                                                    }
                                                                }, "無資料")
                                                            ])
                                                        ])

                                                ] : [

                                                    Cookie.data.map((_cookie, index) => {
                                                        return m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                            style: {
                                                                fontSize: "85%",
                                                                fontWeight: 700,
                                                                lineHeight: 1,
                                                                backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                                            }
                                                        }, [
                                                                m(".col-1.text-center",
                                                                    m("span", index + 1 + (Cookie.paging.pageNo * Cookie.paging.pageSize) - 30)
                                                                ),
                                                                m(".col-5",
                                                                    m("span", _cookie.title)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    (_cookie.isVisit == 1) ? [m("i.fa.fa-check.text-success")] : [m("i.fa.fa-remove.text-danger")]
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _cookie.type)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("span", _cookie.price)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("span", _cookie.sort)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                        style: {
                                                                            backgroundColor: "#e2f0d9"
                                                                        },
                                                                        onclick: (e) => {
                                                                            e.preventDefault()
                                                                            Dialog.show(EditComponent, {
                                                                                attrs: {
                                                                                    model: new Cookie(_cookie),
                                                                                },
                                                                                didHide: (returnValue) => {
                                                                                    if (returnValue) {
                                                                                        Cookie.fetch()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    },
                                                                        [
                                                                            m("i.fa.fa-edit"),
                                                                        ]
                                                                    ),
                                                                    m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                        style: {
                                                                            backgroundColor: "#ffbdbe"
                                                                        },
                                                                        onclick: (e) => {
                                                                            e.preventDefault()
                                                                            swal({
                                                                                title: "是否確認刪除",
                                                                                text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                                icon: "warning",
                                                                                dangerMode: true,
                                                                                buttons: ["取消", "確認"]
                                                                            })
                                                                                .then((willDelete) => {
                                                                                    if (willDelete) {
                                                                                        swal("您的資料已成功刪除", {
                                                                                            icon: "success",
                                                                                            buttons: {
                                                                                                cancel: false,
                                                                                                confirm: "確認",
                                                                                            },
                                                                                        });
                                                                                        Cookie.delete(_cookie.id).then(() => {
                                                                                            Cookie.fetch()
                                                                                        })
                                                                                    } else {
                                                                                        swal("您的資料未被刪除");
                                                                                    }
                                                                                });
                                                                        }
                                                                    },
                                                                        [
                                                                            m("i.fa.fa-trash"),
                                                                        ]
                                                                    )
                                                                ),
                                                            ])

                                                    }),
                                                ]
                                        ]
                                ]
                                ),
                                m('.row.no-gutters.px-5', [
                                    m('.col-12.pt-3', [
                                        m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                            m(Paging.Pagination, {
                                                pageNo: Cookie.paging.pageNo,
                                                pageCount: Cookie.paging.pageCount,
                                                pageUrl: Cookie.fetch.bind(this)
                                            }),
                                            m(Paging.PageItemCount, {
                                                pageNo: Cookie.paging.pageNo,
                                                pageSize: Cookie.paging.pageSize,
                                                total: Cookie.paging.totalRecordCount
                                            })
                                        ])
                                    ])
                                ]),
                            ]
                        )
                    )
                )
            ),
        ]
    }
}

export default CookiePage