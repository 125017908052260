import m from 'mithril'
import AjaxLoader from 'components/preloader/ajax_loader'
import isMobile from 'lib/detect-mobile'
import Chunk from 'lib/chunk'
import Layout from 'app/container/layout'
import * as Page from 'app/pages'
import {
   Authority,
   User,
} from 'app/models'

const root = document.body

window.isMobile = isMobile()

const managerRoutes = {
    '/': Layout(Page.DashboardPage),
    '/news': Layout(Page.NewsPage),
    '/cookie': Layout(Page.CookiePage),
    '/signUp': Layout(Page.SignUpPage),
    '/customer': Layout(Page.CustomerPage),
    '/contactUs': Layout(Page.ContactUsPage),

    

    '/account': Layout(Page.AccountPage),
    "/:404...": Page.ErrorPage,
}

export function render() {
    // const loader = document.getElementById('loader');

    if (window.isMobile){
        $("body").addClass("mobile").removeClass("adminbody");
    }
    
    Promise.all([User.load(window.USER_ID), Authority.fetch(window.USER_ID)]).then(()=>{
        console.log(JSON.parse(User.Account.permission))
        m.route(root, '/', managerRoutes)
    })
}
