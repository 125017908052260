import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    Editor,
} from 'components'

import DatePicker from 'components/datepick/mithril-datepicker.js'
import {
    MultiPhotosUploader,
} from 'components/uploader'


class EditComponent {
    constructor(vnode) {
        this.photos = stream([])
        // this.content = stream("")
        this.dates = []
        this.model = vnode.attrs.model
        this.status = ['甜點', '麵包', '料理','其他']
        if (this.model.id != 0) {
            this.photos(JSON.parse(this.model.photos))
            this.dates = this.model.dates
            // this.content(JSON.parse(this.model.content))
        }
    }
    

    save() {
        // this.model.content = JSON.stringify(this.content())
        this.model.photos = JSON.stringify(this.photos())
        this.model.dates = JSON.stringify(this.dates)
        // console.log(this.model.dates)
        this.model.save().then((response) => {
            Dialog.close(true)
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return [m(".modal-dialog.modal-lg[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", (this.model.id == 0) ? "新增" : "編輯"),
                            m("button.close", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("",
                            m("form[id='basic-form'][method='post'][novalidate]",
                                m('.row.m-0', [
                                    // m(".col-12", [
                                        m("span.badge.badge-info", {
                                            style: {
                                                fontSize: "1rem"
                                            }
                                        }, "文青廚房"),
                                        m("span.pl-3.text-danger", "第一張圖為網站預覽圖"),
                                        m(MultiPhotosUploader, {
                                            items: this.photos,
                                            temppath: `${window.BASE_URL}/temp/`,
                                            storepath: `${window.BASE_URL}/images/cookie/`,
                                            url: `${window.BASE_URL}/api/upload/photo`,
                                            width:640,
                                            height:480,
                                            folder: "cookie"
                                        }),
                                    // ]),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "標題"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.title = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.title,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "老師"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.teacher = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.teacher,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "排序"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.sort = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.sort,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "種類"),
                                            [
                                                m("select.form-control", {
                                                    onchange: (e) => {
                                                        this.model.type = e.target.value
                                                    }
                                                }, [
                                                        m("option"),
                                                        this.status.map((item, index) => {
                                                            return m('option', {
                                                                value: item,
                                                                selected: this.model.type == item
                                                            }, item)
                                                        })
                                                    ]
                                                )
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "價格"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.price = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.price,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m(".col-2", [
                                        m(".form-radio.form-radio-flat.my-2",
                                            m("label.form-check-label.pl-4",
                                                [
                                                    m("input.form-check-input[type='radio']", {
                                                        checked: this.model.isVisit == 1,
                                                        onclick: () => {
                                                            this.model.isVisit = true
                                                        }
                                                    }),
                                                    "顯示",
                                                    m("i.input-helper")
                                                ]
                                            )
                                        ),
                                    ]),
                                    m(".col-2", [
                                        m(".form-radio.form-radio-flat.my-2",
                                            m("label.form-check-label.pl-4",
                                                [
                                                    m("input.form-check-input[type='radio']", {
                                                        checked: this.model.isVisit == 0,
                                                        onclick: () => {
                                                            this.model.isVisit = false
                                                        }
                                                    }),
                                                    "不顯示",
                                                    m("i.input-helper")
                                                ]
                                            )
                                        )
                                    ]),
                                    
                                    m(".row.w-100.pl-3", [
                                        m('.col-md-6',
                                            m(".form-group", [
                                                m("label.text-dark.mb-1", "課程說明"),
                                                [
                                                    m("textarea.form-control", {
                                                        row: "3",
                                                        value: this.model.content,
                                                        oninput: (e) => {
                                                            this.model.content = e.target.value
                                                        },
                                                    })
                                                ]
                                            ]),
                                        ),
                                        m('.col-md-6',
                                            m(".form-group", [
                                                m("label.text-dark.mb-1", "注意事項"),
                                                [
                                                    m("textarea.form-control", {
                                                        row: "3",
                                                        value: this.model.memo,
                                                        oninput: (e) => {
                                                            this.model.memo = e.target.value
                                                        },
                                                    })
                                                ]
                                            ]),
                                        ),
                                    ]),
                                    m(".row.w-100.py-3.pl-3",[
                                        m(".col-12.text-left",[
                                            m("span.text-primary.px-1", {
                                                onclick: (e) => {
                                                    e.preventDefault()                                                    
                                                    this.dates.push({
                                                        id:0,
                                                        name: moment().format("Y-MM-DD"),
                                                        isChoose: 1,
                                                        isVisit: 1,
                                                        time:""
                                                    })
                                                }
                                            }, m("i.fa.fa-plus-circle", {
                                                style: {
                                                    fontSize: "1.5rem"
                                                },
                                            }), m("span.text-dark.pl-2", {
                                                style: {
                                                    fontSize: "1rem"
                                                }
                                            }, "新增開課時間")
                                            )
                                        ])
                                    ]),
                                    m(".row.w-100.py-3.pl-3", [
                                       this.dates.map((_date,index)=>{
                                        return [
                                            m(".col-3.text-center.p-1.m-1.border",[
                                                m(DatePicker, {
                                                    date: _date.name,
                                                    locale: 'en-us',
                                                    formatOptions: {
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                    },
                                                    onchange: (chosenDate) => {
                                                        _date.name = moment(chosenDate).format("Y-MM-DD")
                                                    },
                                                }),
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        _date.time = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: _date.time,
                                                    type: 'text',
                                                }),
                                                m(".pt-2"),
                                                m("button.btn.btn-outline-secondary.p-1.m-1.px-2",{
                                                    onclick:(e)=>{
                                                        e.preventDefault()
                                                        if (_date.isVisit){
                                                            _date.isVisit = false
                                                        }
                                                        else{
                                                            _date.isVisit = true
                                                        }
                                                    }
                                                }, (_date.isVisit == 1) ? [m("span.text-success", "顯示")] : [m("span.text-danger", "不顯示")]),
                                                m("button.btn.btn-outline-secondary.p-1.m-1.px-2", {
                                                    onclick: (e) => {
                                                        e.preventDefault()
                                                        if (_date.isChoose) {
                                                            _date.isChoose = false
                                                        }
                                                        else {
                                                            _date.isChoose = true
                                                        }
                                                    }
                                                }, (_date.isChoose == 1) ? [m("span.text-success", "可選")] : [m("span.text-danger", "不可選")]),
                                                m("a.d-inline.p-2",{
                                                    href:"#",
                                                    onclick:(e)=>{
                                                        e.preventDefault()
                                                        this.dates.splice(index, 1);
                                                    }
                                                }, m("i.fa.fa-trash"))
                                            ])
                                        ]
                                       })
                                    ]),
                                ]),
                                // m('.row.m-0', [
                                //     m(".form-group",
                                //         [
                                //             m("label.control-label", "內容"),
                                //             m(Editor, {
                                //                 content: this.content
                                //             }),
                                //         ]
                                //     )
                                // ]),
                            )
                        )
                    ),

                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                [m("i.fa.fa-times-circle"), "關閉"]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                    swal({
                                        title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                                        text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                                        icon: "success",
                                        buttons: false,
                                        timer: 1800,
                                    })
                                }
                            },

                                [m("i.fa.fa-check-circle"), "儲存"])
                        ],
                    )
                ]
            )
        ])]
    }
}

export default EditComponent
