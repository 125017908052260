import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []
const constraints = {

}

class ContactUs extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.name = args.name || '';
        this.email = args.email || '';
        this.phone = args.phone || '';
        this.content = args.content || '';
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get data() {
        return _data;
    }

    static fetchtime(datefrom,dateto) {
        ContactUs.loading = true
        let url = `${window.BASE_URL}/api/contactUsFindDate/${datefrom}/${dateto}`
        return m.request({
            method: 'GET',
            url: url,
        }).then((response) => {
            ContactUs.loading = false
            _data = response
            return response
        })
    }

    static fetch(page) {
        ContactUs.loading = true
        page = (page === undefined) ? ContactUs.pageIndex : page
        ContactUs.pageIndex = page

        let url = `${window.BASE_URL}/api/contactUs/getPage?pageIndex=${page}`
        return m.request({
            method: 'GET',
            url: url,
        }).then((response) => {
            ContactUs.paging = {
                pageCount: response.last_page,
                pageNo: response.current_page,
                pageSize: response.per_page,
                totalRecordCount: response.total
            }
            _data = response.data
            ContactUs.loading = false
        })
    }

    static load(id) {
        if (id == 0) {
            return m.request({
                method: 'GET',
                url: `${window.BASE_URL}/api/contactUs`,
            })
        }
        else {
            return m.request({
                method: 'GET',
                url: `${window.BASE_URL}/api/contactUs/${id}`,
            })
        }
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/contactUs`,
            data: this
        })
    }

    static delete(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/contactUs/${id}`,
        })
    }
}

export default ContactUs