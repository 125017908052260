export const ManagementItems = [{
    href: '/',
    title: '首頁',
    icon: 'lnr.lnr-home',
    regex: /\//,
    auth: 'dashboard',
}, {
    href: '/news',
    title: '哆夶食間',
    icon: 'lnr.lnr-calendar-full',
    regex: /\/news/,
    auth: '哆夶食間'
}, {
    href: '/cookie',
    title: '文青廚房',
    icon: 'lnr.lnr-book',
    regex: /\/cookie/,
    auth: '文青廚房'
},{
    href: '/signUp',
    title: '線上報名',
    icon: 'lnr.lnr-star',
    regex: /\/signUp/,
    auth: '線上報名'
},{
    href: '/customer',
    title: '客製合作',
    icon: 'lnr.lnr-apartment',
    regex: /\/customer/,
    auth: '客製合作'
},{
    href: '/contactUs',
    title: '聯絡我們',
    icon: 'lnr.lnr-phone-handset',
    regex: /\/contactUs/,
    auth: '聯絡我們'
},
// {
//     href: '/mail',
//     title: '信箱設定',
//     icon: 'lnr.lnr-envelope',
//     regex: /\/mail/,
//     auth: '信箱設定'
// },
// {
//     href: '/account',
//     title: '管理員管理',
//     icon: 'lnr.lnr-cog',
//     regex: /\/account/,
//     auth: '管理員管理'
// }

]



